@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/variables";

.hide-sm {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.hide-not-sm {
    @include media-breakpoint-down(sm) {
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.full-height-without-header {
    height: calc(95vh - 50px);
}

.header-icons {
        font-size: 3.5vh;
        margin: 0;
        text-align: right;
        line-height: 5vh;
        padding: 5px;

        a {
            margin-left: 20px;

            svg {
                path {
                    color: #ffffff;
                }
            }
        }

        a:hover {
            svg {
                path {
                    color: #1f73c7;
                }
            }
        }
    
}