@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/variables";

.abs-section4-container {
    width: 100vw;
    min-height: 70vh;
}

.abs-section4-content-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 70vh;
    padding: 30px;

    @include media-breakpoint-down(md) {
        width: 100vw;
    }

    h2, h4 {
        color: #004F97;
        font-family: 'Poppins', sans-serif;
    }

    p {
        color: #525252;
        font-family: 'Poppins', sans-serif;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    h2 {
        text-align: center;
        font-weight: 700;
        margin-bottom: 40px;
    }

    h4 {
        text-transform: uppercase;
        text-decoration: underline;
        margin-top: 40px;
        cursor: pointer;
    }

}

.abs-experience-company {
    margin-top: 0;
}

.abs-experience-name {
    margin-bottom: 0;
}

.abs-experience-picture {
    display: block;
    margin: auto;
    @include media-breakpoint-down(md) {
        width: 15vw;
    }
    @include media-breakpoint-down(sm) {
        width: 30vw;
    }
    width: 70%;
    margin-bottom: 20px;
}