@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/variables";

.abs-footer-background {
    background-image: url('/assets/banner02.jpg');
    width: 100vw;
    background-position: center;
    background-size: cover;
    padding: 10px;
    padding-top: 5vh;
    padding-bottom: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        width: 100%;
    }
}

.abs-footer-icon {
    display: block;
    margin: auto;
    width: 5vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
    @include media-breakpoint-down(md) {
        width: 10vw
    }
    @include media-breakpoint-down(sm) {
        width: 15vw;
    }
}

.abs-footer-menu {
    display: block;
    margin: auto;
}

.abs-footer-menu-item {
    font-family: 'Poppins', sans-serif;
    color: white;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
}

.abs-footer-menu-item:hover {
    color: #26F6FF;
}

.abs-footer-copyright {
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-top: 20px;
    font-size: 80%;
}

.abs-footer-center {
    text-align: center;
}

.abs-footer-title {
    text-align: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

.abs-footer-form-container {
    width: 50vw;
    @include media-breakpoint-down(md) {
        width: 70vw
    }
    @include media-breakpoint-down(sm) {
        width: 90vw;
    }
}