.form-label {
    color: white;
    font-family: 'Poppins';
}

.form-input {
    background-color: transparent;
    color: white;
    font-family: 'Poppins';
    border: 1.5px solid white;
}

.form-input:focus {
    background-color: transparent;
    color: white;
    border: 1.5px solid white; //#003780;
    box-shadow: none;
}

.form-input::placeholder {
    color: rgb(175, 175, 175);
}

.form-invalid {
    border: 2px solid rgb(216, 0, 0);
}