@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/variables";

.abs-section3-container {
    background: rgb(0,20,73);
    background: linear-gradient(180deg, rgba(0,20,73,1) 0%, rgba(1,16,45,1) 100%);
    width: 100vw;
    padding-top: 15vh;
    padding-bottom: 15vh;
    padding-right: 30px;
    padding-left: 30px;
    @include media-breakpoint-down(md) {
        min-height: 100vh;
        height: auto;
    }
}

.abs-section3-text {
    h2, h4, h6, p {
        font-family: 'Poppins', sans-serif;
        color: white;
        text-align: center;
    }

    h2 {
        font-weight: 700;
        margin-bottom: 40px;
    }

    h4 {
        font-weight: 600;
    }
}

.abs-section3-spacer {
    height: 50px;
}

.abs-section3-icon {
    img {
        height: 70px;
    }
    display: flex;
    justify-content: center;
    padding: 20px;
}

.abs-section3-col {
    padding: 20px;
}

.abs-section1-content {
    display: inline-block;
    width: 50vw;
    height: 85vh;
    vertical-align: top;

    @include media-breakpoint-down(md) {
        width: 100vw;
        min-height: 80vh;
        height: auto;
    }
}

.abs-section1-image {
    background-image: url('/assets/banner03.jpg');
    background-position: center;
    background-size: cover;
}

.abs-section1-background {
    background-color: #004F97;
}

.abs-section1-content-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 80vh;
    padding: 30px;
    padding-right: 150px;

    @include media-breakpoint-down(md) {
        width: 100vw;
        padding-right: 30px;
        height: auto;
        min-height: 80vh;
        padding-top: 10vh;
        padding-bottom: 10vh;
    }

    h2, p, h5 {
        color: white;
        font-family: 'Poppins', sans-serif;
    }

    h2 {
        font-weight: 700;
        margin-bottom: 40px;
    }

    h5 {
        text-transform: uppercase;
        text-decoration: underline;
        margin-top: 40px;
        cursor: pointer;

        @include media-breakpoint-down(sm) {
            font-size: 95%;
        }
    }

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

}

.abs-section1-icon {
    margin-right: 20px;
}