.header 
{
    font-weight: 700;
}

h2, p 
{
    font-family: 'Poppins';
}

.white 
{
    color: white;
}