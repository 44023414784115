@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/variables";

.abs-button {
    background-color: #26F6FF;
    color: #022039;
    padding: 10px;
    border-radius: 5px;
    border: 0;
    margin-top: 15px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    outline: none;
    padding-left: 20px;
    padding-right: 20px;

    @include media-breakpoint-down(sm) {
        display: block;
        margin: auto;
    }
}

.abs-button:hover {
    background-color: #13dbe2;
}

.abs-button:focus {
    outline: none;
}