@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
@import "~bootstrap/scss/mixins";

.section1 {
  background: linear-gradient(90deg, #003780d9 0%, #80a1ccd9 100%);
  height: 100vh;
  width: 100vw;
}

.section1::before {
  background-image: url('/assets/bg1.jpg');
  background-size: cover;
  content: "";
  z-index: -10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.section3 {
  background: linear-gradient(90deg, #F3C25Dd9 0%, #FCE1A9d9 100%),
  url('/assets/bg3.jpg');
  height: 100vh;
  width: 100vw;
  background-size: cover;
}

.section4 {
  background: linear-gradient(90deg, #4a4f95d9 0%, #676cadd9 100%),
  url('/assets/bg3.jpg');
  height: 100vh;
  width: 100vw;
  background-size: cover;
}

.section5 {
  background: #e0e0e0;
  height: 100vh;
  width: 100vw;
  background-size: cover;
}

.section1-legal {
  background: linear-gradient(90deg, #003780d9 0%, rgba(128, 161, 204, 0.85) 100%);
  height: 50vh;
  width: 100vw;
}

.section1-legal::before {
  background-image: url('/assets/bg-legal.jpg');
  height: 50vh;
  background-size: cover;
  content: "";
  z-index: -10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

body {
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;
}

a:hover {
  text-decoration: none !important;
}