.abs-player-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.abs-video-container {
    width: 85vw;
    height: 9 * 85vw / 16;
}

.abs-player-background {
    height: 100vh;
    width: 100vw;
    background-image: url("/assets/banner02.jpg");
    position: absolute;
    top: 0;
    z-index: -10;
    background-position: center;
    background-size: cover;
}

.abs-player-blur {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    z-index: -5;
    backdrop-filter: blur(10px);
}

.abs-icon-container {
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100vw;
    padding: 3vh;

    p {
        color: white;
        font-size: 2em;
        text-align: right;
    }
}